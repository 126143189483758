







































































import Vue from "vue";
import FormHeader from "@/components/Forms/FormHeader.vue";
import { updateMedicalDetails } from "@/utils/api";
import FieldError from "@/components/Forms/FieldError.vue";
import store, { actionTypes } from "@/store";

export default Vue.extend({
  props: {
    onSave: Function,
    update: Boolean,
  },
  components: {
    FormHeader,
    FieldError,
  },
  data() {
    return {
      showPassword: false,
      generalError: "",
      formErrors: {} as any,
      submitting: false,
      formData: {
        medical_problems: "",
        allergies: "",
        medication: "",
        vegetarian: false,
      },
    };
  },
  mounted() {
    this.setFormData();
  },
  methods: {
    onSubmit(e: any) {
      e.preventDefault();
      if (this.submitting) return;
      this.submitting = true;
      updateMedicalDetails(store.state.user.member.id, this.formData)
        .then((res) => {
          store.dispatch(actionTypes.setMember, res.data.member);
          this.onSave();
        })
        .catch((e) => {
          this.submitting = false;
          if (!e.response || !e.response.data || !e.response.data.errors) {
            this.generalError = "There was an error. Please try again later";
            return;
          }
          this.formErrors = e.response.data.errors;
        });
    },
    onCancel() {
      this.$router.go(-1);
    },
    hasError(field: string) {
      return this.formErrors[field as any] != null;
    },
    getError(field: string) {
      const error = this.formErrors[field as any];
      return error ? error[0] : "";
    },
    setFormData() {
      if (this.update && store.state.user.has_member) {
        const m = store.state.user.member;
        this.formData = {
          medical_problems: m.medical_problems ?? "",
          allergies: m.allergies ?? "",
          medication: m.medication ?? "",
          vegetarian: !!m.vegetarian,
        };
      }
    },
  },
});
