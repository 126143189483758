import store from "@/store";
import axios, { AxiosResponse } from "axios";
import { apiConfig, getToken } from "./helpers";
import { PersonalDetails } from "./types";

const host = process.env.VUE_APP_API_BASE;

const getAuthConfig = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

export const fetchProfile = () => {
  return axios.get(`${host}/api/profile`);
};

export const getTransactions = (member_id: number, page = 1) => {
  return axios.get(
    `${host}/api/members/${member_id}/transactions?page=${page}`,
    getAuthConfig()
  );
};

export const fetchMeta = () => {
  return axios.get(`${host}/api/meta`);
};

export const fetchEventsForDate = (date: string) => {
  return axios.get(`${host}/api/events/date/${date}`);
};

export const fetchEvent = (id: number) => {
  return axios.get(`${host}/api/events/${id}`);
};

export const fetchBlog = (id: number) => {
  return axios.get(`${host}/api/blogs/${id}`);
};

export const fetchBlogs = (filter = "page=1") => {
  return axios.get(`${host}/api/blogs?${filter}`);
};

export const fetchPastEvents = (page = 1) => {
  return axios.get(`${host}/api/events/past?page=${page}`);
};

export const fetchUpcomingEvents = (member_id: null | number) => {
  return axios.get(
    `${host}/api/events/upcoming${member_id ? `?member_id=${member_id}` : ""}`
  );
};

export const login = (credentials: {
  email: string;
  password: string;
}): Promise<AxiosResponse> => {
  return axios.post(`${host}/api/auth/login`, credentials);
};

export const logout = (): Promise<AxiosResponse> => {
  return axios.delete(`${host}/api/auth/logout`, getAuthConfig());
};

export const register = (credentials: {
  email: string;
  password: string;
}): Promise<AxiosResponse> => {
  return axios.post(`${host}/api/auth/users/register`, credentials);
};

export const registerForEvent = (event_id: number): Promise<AxiosResponse> => {
  return axios.post(
    `${host}/api/events/${event_id}/register`,
    [],
    getAuthConfig()
  );
};

export const linkAccount = (member_id: number) => {
  return axios.post(
    `${host}/api/auth/users/link/${member_id}`,
    [],
    getAuthConfig()
  );
};

export const getMembers = (filter: string) => {
  return axios.get(`${host}/api/members?${filter}`, getAuthConfig());
};

export const createMember = (data: PersonalDetails): Promise<AxiosResponse> => {
  return axios.post(`${host}/api/members`, data, getAuthConfig());
};

export const updateMember = (
  memberId: number,
  data: PersonalDetails
): Promise<AxiosResponse> => {
  return axios.patch(`${host}/api/members/${memberId}`, data, getAuthConfig());
};

export const updateMemberPicture = (
  memberId: number,
  data: any
): Promise<AxiosResponse> => {
  return axios.post(
    `${host}/api/members/${memberId}/profile-picture?_method=PATCH`,
    data,
    getAuthConfig()
  );
};

export const updateMedicalDetails = (
  memberId: number,
  data: {
    medical_problems: string;
    allergies: string;
    medication: string;
    vegetarian: boolean;
  }
) => {
  return axios.patch(
    `${host}/api/members/${memberId}/medical`,
    data,
    getAuthConfig()
  );
};

export const updateChurchMembership = (
  memberId: number,
  data: {
    year_of_baptism: string;
    church: string;
    church_address: string;
    church_pastor: string;
  }
) => {
  return axios.patch(
    `${host}/api/members/${memberId}/church-membership`,
    data,
    getAuthConfig()
  );
};

export const getMember = () => {
  return axios.get(`${host}/api/members/profile`, getAuthConfig());
};

export const declareMembership = (
  memberId: number,
  data: {
    declaration: boolean;
  }
) => {
  return axios.patch(`${host}/api/members/${memberId}`, data, getAuthConfig());
};
