
























import Vue from "vue";
import UpcomingEventsCard from "@/components/Home/PreviousEvents/PreviousEventsCard.vue";
import { fetchPastEvents } from "@/utils/api";
import { IEvent } from "@/utils/types";

export default Vue.extend({
  components: {
    UpcomingEventsCard,
  },
  mounted() {
    this.getEvents();
  },
  data() {
    return {
      events: [] as IEvent[],
    };
  },
  methods: {
    getEvents() {
      fetchPastEvents().then((res) => {
        this.events = res.data.events.data;
      });
    },
  },
});
