















import Vue from "vue";
import Container from "@/components/Dashboard/_DashboardContainer.vue";
import Profile from "@/components/Dashboard/Profile.vue";

export default Vue.extend({
  components: {
    Container,
    Profile,
  },
});
