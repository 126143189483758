










































































































































































import Vue from "vue";
import store, { actionTypes } from "@/store";
import AttrTitle from "@/components/Dashboard/AttrTitle.vue";
import ProfileDetail from "@/components/Dashboard/ProfileDetail.vue";
import { getMember, updateMemberPicture } from "@/utils/api";
import moment from "moment";
import { IMedia } from "@/utils/types";

export default Vue.extend({
  components: {
    AttrTitle,
    ProfileDetail,
  },
  props: {
    onLogin: Function,
  },
  mounted() {
    getMember().then((res) => {
      store.dispatch(actionTypes.setMember, res.data.member);
    });
  },
  methods: {
    getValue: (value: any) => {
      return value == null ? "N/A" : value;
    },
    onChangeProfileImage: (e: any) => {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      let data = new FormData();
      data.append("profile_photo", e.target.files[0]);
      updateMemberPicture(store.state.user.member.id, data).then((res) => {
        store.dispatch(actionTypes.setMember, res.data.member);
      });
    },
  },
  computed: {
    img: () => {
      const image =
        store.state.user.has_member && store.state.user.member
          ? store.state.user.member.media.find(
              (i: IMedia) => i.collection_name == "profile"
            )?.original_url
          : null;
      return (
        image ??
        "https://res.cloudinary.com/c99/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1649373695/unnamed.png"
      );
    },
    user: () => store.state.user,
    yearJoined: () =>
      moment(store.state.user.member.year_joined).format("YYYY"),
    dateOfBirth: () =>
      moment(store.state.user.member.dob).format("DD MMM YYYY"),
    yearOfBaptism: () =>
      moment(store.state.user.member.year_of_baptism).format("DD MMM YYYY"),
  },
});
