<template>
  <div class="flex w-full" @click="onClick">
    <div class="p-4 bg-red-400 w-full">
      <div
        class="
          my-2
          text-sm
          w-3.5
          h-3.5
          rounded-full
          b-2
          border-black
          text-red-900
          whitespace-nowrap
          font-bold
        "
      >
        {{ time }}
      </div>
      <div class="text-white font-bold">{{ event.name }}</div>
      <div class="text-white">{{ event.location }}</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    event: Object,
  },
  methods: {
    onClick() {
      this.$router.push(`/events/${this.event.id}`);
    },
  },
  computed: {
    time() {
      return moment(this.event.start_time).format("DD MMM YYYY");
    },
  },
});
</script>
