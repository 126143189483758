



























































import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  props: {
    event: Object,
  },
  computed: {
    month() {
      return moment(this.event.start_time).format("MMM");
    },
    day() {
      return moment(this.event.start_time).format("DD");
    },
    time() {
      return moment(this.event.start_time).format("ddd HH:mm");
    },
  },
});
