











































import Vue from "vue";
import FormHeader from "../FormHeader.vue";
import { declareMembership } from "@/utils/api";
import FieldError from "@/components/Forms/FieldError.vue";
import store from "@/store";

export default Vue.extend({
  components: { FormHeader, FieldError },
  props: {
    onSave: Function,
  },
  data() {
    return {
      showPassword: false,
      generalError: "",
      formErrors: {} as any,
      submitting: false,
      formData: {
        declaration: false,
      },
    };
  },
  methods: {
    onSubmit(e: any) {
      e.preventDefault();
      if (this.submitting) return;
      if (!this.formData.declaration)
        return (this.generalError = "Please declare to proceed");
      this.submitting = true;
      declareMembership(store.state.user.member.id, this.formData)
        .then(() => {
          this.onSave();
        })
        .catch((e) => {
          this.submitting = false;
          if (!e.response || !e.response.data || !e.response.data.errors) {
            this.generalError = "There was an error. Please try again later";
            return;
          }
          this.formErrors = e.response.data.errors;
        });
    },
  },
});
