




















import Vue from "vue";
import Sidebar from "@/components/Dashboard/SideBar.vue";
import store from "@/store";
import router from "@/router";

export default Vue.extend({
  props: {
    active: String,
  },
  data: function () {
    return {
      loggedIn: false,
    };
  },
  components: {
    Sidebar,
  },
  mounted() {
    if (store.state.user.authenticated) {
      this.loggedIn = true;
      return;
    }
    router.push("/auth/login");
  },
});
