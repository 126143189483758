




























































import router from "@/router";
import { getMembers, linkAccount } from "@/utils/api";
import { saveSession } from "@/utils/helpers";
import Vue from "vue";

export default Vue.extend({
  components: {},
  data() {
    return {
      generalError: "",
      formErrors: {} as { name?: Array<string> },
      submitting: false,
      message: "",
      members: [] as any,
      formData: {
        name: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.formErrors = {};
      const names = this.formData.name.split(" ");
      if (this.formData.name.length == 0) {
        this.formErrors = { name: ["Name can not be empty"] };
        return;
      }
      if (names.length < 2) {
        this.formErrors = { name: ["Please provide a surname"] };
        return;
      }

      let query = `like[0]=firstname,${names[0]}&like[1]=surname,${names[1]}`;
      getMembers(query)
        .then((res) => {
          const members = res.data.members;
          this.message =
            "Please select the name that best matches your details";

          if (members.length == 0) this.message = "No match for name";

          this.members = members;
        })
        .catch(() => {
          this.message = "There was un error while linking, please try again";
        });
    },
    onLink(member: any) {
      this.$swal
        .fire({
          text: `Are you sure you want to link your account with ${member.firstname} ${member.surname}?`,
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.message = "Linking, please wait ...";
            this.members = [];
            linkAccount(member.id)
              .then((res) => {
                saveSession(res.data.auth);
                router.push("/dashboard/profile");
              })
              .catch((e) => {
                if (!e.response || !e.response.data) {
                  this.message =
                    "There was un error while linking, please try again";
                  return;
                }
                this.message = e.response.data.message;
              });
          }
        });
    },
    hasError(field: string) {
      return this.formErrors[field as "name"] != null;
    },
    getError(field: string) {
      const error = this.formErrors[field as "name"];
      return error ? error[0] : "";
    },
  },
});
