

























































import { fetchEventsForDate } from "@/utils/api";
import { IEvent } from "@/utils/types";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      selectedEvent: {},
      selectedElement: null as EventTarget | null,
      selectedOpen: false,
      events: [] as Array<
        IEvent & {
          start: Date;
          end: Date;
          color: string;
        }
      >,
      colors: ["blue", "indigo", "green", "purple", "red"],
    };
  },
  mounted(): void {
    (this.$refs.calendar as Vue & { checkChange: () => void }).checkChange();
  },
  methods: {
    viewDay({ date }: { date: string }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event: {
      name: string;
      start: Date;
      end: Date;
      color: string;
      timed: boolean;
    }) {
      return event.color;
    },
    setToday(): void {
      this.focus = "";
    },
    prev(): void {
      (this.$refs.calendar as Vue & { prev: () => void }).prev();
    },
    next(): void {
      (this.$refs.calendar as Vue & { next: () => void }).next();
    },
    showEvent({ nativeEvent, event }: { nativeEvent: Event; event: IEvent }) {
      this.$router.push(`/events/${event.id}`);
      nativeEvent.stopPropagation();
    },
    updateRange({
      start,
      end,
    }: {
      start: { date: string };
      end: { date: string };
    }): void {
      const min = new Date(`${start.date}`);
      const max = new Date(`${end.date}`);

      fetchEventsForDate(moment(start.date).format("YYYY-MM")).then((res) => {
        this.events = res.data.events.map((e: IEvent) => ({
          ...e,
          start: new Date(e.start_time),
          end: new Date(e.end_time),
          color: this.colors[Math.ceil(e.id % this.colors.length)],
        }));
      });
    },
    rnd(a: number, b: number) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
});
