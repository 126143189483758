











import Vue from "vue";
export default Vue.extend({
  props: {
    tag: String || null,
  },
});
