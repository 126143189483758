


































































import Vue from "vue";
import { login } from "../../utils/api";
import FieldError from "@/components/Forms/FieldError.vue";
import { saveSession } from "../../utils/helpers";

export default Vue.extend({
  components: {
    FieldError,
  },
  props: {
    onLogin: Function,
  },
  data() {
    return {
      showPassword: false,
      generalError: "",
      formErrors: {} as { email?: Array<string>; password?: Array<string> },
      submitting: false,
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    onSubmit(e: any) {
      e.preventDefault();
      if (this.submitting) return;
      this.submitting = true;
      login(this.formData)
        .then((res) => {
          saveSession(res.data.auth);
          this.onLogin(res.data);
        })
        .catch((e) => {
          this.submitting = false;
          if (!e.response || !e.response.data || !e.response.data.errors) {
            this.generalError = "There was an error. Please try again later";
            return;
          }
          this.generalError =
            e.response.data.message ??
            "There was an error. Please try again later";

          this.formErrors = e.response.data.errors;
        });
    },
    onCancel() {
      this.$router.go(-1);
    },
    hasError(field: string) {
      return this.formErrors[field as "email" | "password"] != null;
    },
    getError(field: string) {
      const error = this.formErrors[field as "email" | "password"];
      return error ? error[0] : "";
    },
  },
});
