
























import Vue from "vue";
import UpcomingEventsListCard from "@/components/Events/UpcomingEvents/UpcomingEventsListCard.vue";
import SectionTitle from "@/components/Title/SectionTitle.vue";
import Calendar from "./Calendar.vue";

export default Vue.extend({
  props: {
    events: Array,
  },
  components: {
    UpcomingEventsListCard,
    SectionTitle,
    Calendar,
  },
});
