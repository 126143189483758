


























































































import Vue from "vue";
import FormHeader from "@/components/Forms/FormHeader.vue";
import { updateChurchMembership } from "@/utils/api";
import FieldError from "@/components/Forms/FieldError.vue";
import moment from "moment";
import store, { actionTypes } from "@/store";

export default Vue.extend({
  props: {
    onSave: Function,
    update: Boolean,
  },
  components: {
    FormHeader,
    FieldError,
  },
  data() {
    return {
      showPassword: false,
      generalError: "",
      formErrors: {} as any,
      submitting: false,
      formData: {
        year_of_baptism: "",
        church: "",
        church_address: "",
        church_pastor: "",
      },
      menu: false,
    };
  },
  methods: {
    onSubmit(e: any) {
      e.preventDefault();
      if (this.submitting) return;
      this.submitting = true;

      updateChurchMembership(store.state.user.member.id, {
        ...this.formData,
        year_of_baptism: moment(this.formData.year_of_baptism).format(
          "YYYY-MM-DD"
        ),
      })
        .then((res) => {
          store.dispatch(actionTypes.setMember, res.data.member);
          this.onSave();
        })
        .catch((e) => {
          this.submitting = false;
          if (!e.response || !e.response.data || !e.response.data.errors) {
            this.generalError = "There was an error. Please try again later";
            return;
          }
          this.formErrors = e.response.data.errors;
        });
    },
    onCancel() {
      this.$router.go(-1);
    },
    hasError(field: string) {
      return this.formErrors[field as any] != null;
    },
    getError(field: string) {
      const error = this.formErrors[field as any];
      return error ? error[0] : "";
    },
    setFormData() {
      if (this.update && store.state.user.has_member) {
        const m = store.state.user.member;
        this.formData = {
          year_of_baptism: m.year_of_baptism,
          church: m.church ?? "",
          church_address: m.church_address ?? "",
          church_pastor: m.church_pastor ?? "",
        };
      }
    },
  },
  computed: {
    max: () => {
      return moment().format("YYYY");
    },
  },
});
