

























































import Vue from "vue";
import store from "@/store";
import { IBlog, IMedia } from "@/utils/types";
import Banner from "@/components/Home/Banner/Banner.vue";
import { fetchBlogs } from "@/utils/api";
import moment from "moment";
import BlogCard from "@/components/Home/Blog/BlogCard.vue";
import PageProgress from "@/components/PageProgress/PageProgress.vue";

export default Vue.extend({
  name: "About",
  data() {
    return {
      per_page: 7,
      loading: true,
      last_page: false,
      blogs: [],
    };
  },
  mounted() {
    this.getBlogs();
  },
  components: {
    Banner,
    BlogCard,
    PageProgress,
  },
  watch: {
    per_page(newP, oldP) {
      if (newP != oldP) this.getBlogs();
    },
  },
  methods: {
    getBlogs() {
      this.loading = true;
      fetchBlogs(`limit=${this.per_page}`).then((res) => {
        this.blogs = res.data.blogs.data;
        if (res.data.blogs.to == res.data.blogs.total) this.last_page = true;
        this.loading = false;
      });
    },
    img(blog: IBlog) {
      return blog.media?.find((i: IMedia) => i.collection_name == "cover")
        ?.original_url;
    },
    createdAt(blog: IBlog) {
      return moment(blog.created_at).format("DD MMM YY - HH:mm");
    },
    addPage() {
      return (this.per_page += 6);
    },
  },
  computed: {
    profile() {
      return store.state.profile;
    },
  },
});
