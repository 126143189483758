























import Vue from "vue";
import SectionTitle from "@/components/Title/SectionTitle.vue";
import PreviousEventsCard from "./PreviousEventsCard.vue";
import { fetchPastEvents } from "@/utils/api";
export default Vue.extend({
  data() {
    return {
      page: 1,
      events: {
        data: [],
        current_page: 1,
        first_page_url: null,
        from: 1,
        last_page: 1,
        last_page_url: null,
        links: [],
        next_page_url: null,
        path: null,
        per_page: 10,
        prev_page_url: null,
        to: 10,
        total: 1000,
      },
    };
  },
  components: {
    SectionTitle,
    PreviousEventsCard,
  },
  mounted() {
    this.getPrevEvents();
  },
  methods: {
    getPrevEvents() {
      fetchPastEvents(this.page).then((res) => {
        this.events = res.data.events;
      });
    },
  },
});
