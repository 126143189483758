import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Signup from "../views/auth/Signup.vue";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import SelectRegType from "../views/auth/SelectRegType.vue";
import Events from "../views/events/Events.vue";
import Event from "../views/events/Event.vue";
import Blogs from "../views/blog/Blogs.vue";
import Blog from "../views/blog/Blog.vue";
import Profile from "../views/dashboard/Profile.vue";
import UpdateProfile from "../views/dashboard/UpdateProfile.vue";
import Schedule from "../views/dashboard/Schedule.vue";
import Finance from "../views/dashboard/Finance.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/dashboard/profile/update/:form",
    name: "Profile",
    component: UpdateProfile,
  },
  {
    path: "/dashboard/schedule",
    name: "Schedule",
    component: Schedule,
  },
  {
    path: "/dashboard/finance",
    name: "Finance",
    component: Finance,
  },
  {
    path: "/auth/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/auth/registration-type",
    name: "Registration Type",
    component: SelectRegType,
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
  },
  {
    path: "/events/:event",
    name: "Event",
    component: Event,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blogs,
  },
  {
    path: "/blog/:blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior: () => {
    window.scrollTo(0, 0);
  },
});

export default router;
