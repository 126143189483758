<template>
  <div class="flex relative cursor-pointer" @click="onClick">
    <div class="wrapper-bg"></div>
    <div class="flex items-center absolute h-full w-full top-0 left-0">
      <div class="w-1/2">
        <img class="img shadow-xl" :src="img" alt="" />
      </div>
      <div class="flex items-center h-full w-1/2 bg-red-300 p-4 pl-20">
        <div>
          <div class="text-lg text-white font-bold">{{ event.name }}</div>
          <div class="text-sm">
            {{ event.desription }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    event: Object,
  },
  methods: {
    onClick() {
      this.$router.push(`/events/${this.event.id}`);
    },
  },
  computed: {
    img() {
      return this.event.media.find((i) => i.collection_name == "cover")
        ?.original_url;
    },
  },
});
</script>
<style scoped>
.wrapper-bg {
  position: relative;
  padding-top: 50%;
  width: 100%;
}
.img {
  height: 90%;
  transform: translateX(3rem);
}
</style>
