












import Vue from "vue";
import UpcomingEventCard from "./UpcomingEventCard.vue";

export default Vue.extend({
  props: {
    events: Array,
  },
  components: {
    UpcomingEventCard,
  },
});
