<template>
  <div class="bg-white rounded shadow-sm w-80">
    <div class="flex p-2 items-baseline">
      <div class="w-20 text-sm">
        <div class="bg-red-300 text-center rounded-t text-red-700 p-1">
          <div class="py-2">{{ day }}</div>
        </div>
        <div class="w-full text-center rounded-b bg-red-700 text-white py-1">
          {{ time }}
        </div>
      </div>
      <div class="px-2 text-sm md:text-base my-2" @click="onClick">
        <div class="font-bold">{{ event.name }}</div>
        <div class="text-red-700 underline">Event Details</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import router from "@/router";

export default Vue.extend({
  props: {
    event: Object,
  },
  methods: {
    onClick() {
      router.push(`events/${this.event.id}`);
    },
  },
  computed: {
    day() {
      return moment(this.event.start_time).format("MMM D");
    },
    time() {
      return moment(this.event.start_time).format("HH:mm");
    },
  },
});
</script>
