



























































































import Vue from "vue";
import Container from "@/components/Dashboard/_DashboardContainer.vue";
import { getTransactions } from "@/utils/api";
import store from "@/store";
import moment from "moment";

export default Vue.extend({
  components: {
    Container,
  },
  mounted() {
    this.getTransactions();
  },
  data() {
    return {
      colors: ["red", "indigo", "blue", "green", "purple"],
      page: 1,
      transactions: {
        data: [],
        current_page: 1,
        first_page_url: null,
        from: 1,
        last_page: 1,
        last_page_url: null,
        links: [],
        next_page_url: null,
        path: null,
        per_page: 10,
        prev_page_url: null,
        to: 10,
        total: 0,
      },
    };
  },
  methods: {
    color(id: number) {
      return this.colors[Math.ceil(id % this.colors.length)];
    },
    getTransactions() {
      if (store.state.user.member)
        getTransactions(store.state.user.member.id, this.page).then((res) => {
          this.transactions = res.data.transactions;
        });
    },
    getDate(date: string) {
      return moment(date).format("DD MMM YYYY");
    },
    getAmount(inc: any, out: any) {
      return out ? `${out}` : inc;
    },
    transColor: (item: any) => {
      return item.in ? "text-green-500 font-bold" : "text-red-500 font-bold";
    },
  },
});
