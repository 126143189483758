<template>
  <div class="wrapper">
    <div class="container mx-auto h-full">
      <div class="h-full flex items-center">
        <div class="md:flex items-center justify-between w-full">
          <div class="mx-4">
            <img
              class="w-16 md:w-32"
              src="@/assets/images/bird.svg"
              alt="Bird"
            />
            <div
              class="text-red-700 text-xl md:text-4xl font-bold my-2 md:my-4"
            >
              {{ landing.line1 }}
            </div>
            <div class="text-white font-bold text-lg md:text-5xl my-2 md:my-4">
              {{ landing.line2 }}
            </div>
            <div
              class="text-white text-xs sm:text-sm md:text-base md:w-1/2 my-4"
            >
              {{ landing.line3 }}
            </div>
          </div>
          <div v-if="event" class="mx-4">
            <div
              class="
                text-red-700 text-xl
                md:text-4xl
                font-bold
                my-4
                whitespace-nowrap
              "
            >
              {{ event.name }}
            </div>

            <div
              class="
                text-white text-base
                md:text-4xl
                flex
                items-center
                my-2
                md:my-4
              "
            >
              <font-awesome-icon icon="map-marker-alt" />
              <div class="mx-4 font-bold">{{ event.location }}</div>
            </div>
            <countdown :time="time(event)">
              <template slot-scope="props">
                <div class="flex text-white font-bold">
                  <div
                    class="text-2xl sm:text-4xl md:text-9xl"
                    v-if="props.days > 0"
                  >
                    {{ props.days }}<span class="text-base">Days</span>
                  </div>
                  <div class="text-2xl sm:text-4xl md:text-9xl ml-6">
                    {{ props.hours }}<span class="text-base">Hours</span>
                  </div>
                  <div class="text-2xl sm:text-4xl md:text-9xl ml-6">
                    {{ props.minutes }}<span class="text-base">mins</span>
                  </div>
                  <div
                    class="text-2xl sm:text-4xl md:text-9xl ml-6"
                    v-if="props.days < 1"
                  >
                    {{ props.seconds }}<span class="text-base">sec</span>
                  </div>
                </div>
              </template>
            </countdown>
            <button
              v-if="!event.member_registered"
              @click="() => registerForEvent(event)"
              class="
                w-full
                mt-10
                md:mt-20
                mb-4
                bg-red-700
                hover:bg-red-500
                text-white
                font-bold
                py-2
                px-4
                rounded-sm
              "
            >
              Register for event
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(19, 6, 36, 0.9), rgba(19, 6, 36, 0.9)),
    url("~@/assets/images/bg.jpg");
  background-size: cover;
}
</style>

<script>
import Vue from "vue";
import store from "@/store";
import moment from "moment";
import { IEvent } from "@/utils/types";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { register, registerForEvent as eventRegistration } from "@/utils/api";
import router from "@/router";

Vue.component(VueCountdown.name, VueCountdown);

export default Vue.extend({
  props: {
    events: Array,
  },
  data() {
    return {
      isLive: false,
      registered: false,
    };
  },
  methods: {
    time(event) {
      return moment(event.start_time).diff(moment());
    },
    registerForEvent(event) {
      if (!store.state.user.authenticated || !store.state.user.has_member) {
        router.push("/auth/login");
        return;
      }
      eventRegistration(event.id).then(() => (this.registered = true));
    },
  },
  computed: {
    landing: () => store.state.profile.landing,
    event() {
      return this.events && this.events.length > 0 ? this.events[0] : null;
    },
  },
});
</script>
