








import Vue from "vue";
import Login from "@/components/Forms/Login.vue";
import router from "@/router";

export default Vue.extend({
  components: {
    Login,
  },
  methods: {
    onSubmit(data: any) {
      if (!data.auth.has_member) return router.push("/auth/registration-type");
      this.$router.go(-1);
    },
  },
});
