<template>
  <div class="fixed top-0 left-0 w-full wrap">
    <Progress indeterminate :rounded="false" />
  </div>
</template>
<script>
import Progress from "@/components/Progress/Progress.vue";
export default {
  components: {
    Progress,
  },
  props: {
    loading: Boolean,
  },
};
</script>
<style>
.wrap {
  z-index: 1000;
}
</style>
