














import Vue from "vue";
import { IEvent } from "@/utils/types";
import { fetchUpcomingEvents } from "@/utils/api";
import moment from "moment";
import store from "@/store";
export default Vue.extend({
  data() {
    return {
      upcomingEvents: [] as IEvent[],
      months: [] as string[],
      date: {
        year: moment().format("YYYY"),
        month: moment().format("MMM"),
      },
      years: [] as string[],
      colors: ["red", "green", "purple", "blue", "indigo"],
    };
  },
  mounted() {
    this.getUpcomingEvents();
    this.getMonths();
    this.getYears();
  },
  methods: {
    getUpcomingEvents() {
      fetchUpcomingEvents(store.state.user.member?.id).then((res) => {
        this.upcomingEvents = res.data.events;
      });
    },
    getMonths() {
      this.months = moment.monthsShort();
    },
    getYears() {
      let years: string[] = [];
      let currentYear = moment().format("YYYY");
      let start = Number(currentYear) - 5;
      let end = Number(currentYear) + 2;
      for (let year = start; year <= end; year++) {
        years.push(`${year}`);
      }
      this.years = years;
    },
    time(event: IEvent) {
      return moment(event.start_time).format("MMM DD, YYYY @ HH:mm");
    },
    color() {
      return this.colors[Math.ceil(Math.random() * this.colors.length - 1)];
    },
  },
});
