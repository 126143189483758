




























import { IMedia } from "@/utils/types";
import Vue from "vue";
export default Vue.extend({
  props: {
    event: Object,
  },
  computed: {
    img() {
      const img = this.event?.media.find(
        (i: IMedia) => i.collection_name == "cover"
      );
      if (img) return img.original_url;
      return "~@/assets/images/praying_kid.png";
    },
  },
});
