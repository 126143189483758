




























import Vue from "vue";
import { IEvent } from "@/utils/types";
import { fetchEventsForDate, fetchUpcomingEvents } from "@/utils/api";
import moment from "moment";
import store from "@/store";
export default Vue.extend({
  data() {
    return {
      events: [] as IEvent[],
      months: [] as string[],

      year: moment().format("YYYY"),
      month: moment().format("MMM"),

      years: [] as string[],
    };
  },
  mounted() {
    this.getEvents();
    this.getMonths();
    this.getYears();
  },

  methods: {
    onChangeYear(year: any) {
      this.year = year;
      fetchEventsForDate(this.getDate(year, this.month)).then((res) => {
        this.events = res.data.events;
      });
    },
    onChangeMonth(month: any) {
      this.month = month;
      fetchEventsForDate(this.getDate(this.year, month)).then((res) => {
        this.events = res.data.events;
      });
    },
    getDate(year = null as any, month = null as any) {
      return year
        ? moment(`${year}${month}`, "YYYYMMM").format("YYYY-MM")
        : moment(`${this.year}${this.month}`, "YYYYMMM").format("YYYY-MM");
    },
    getEvents() {
      fetchEventsForDate(this.getDate()).then((res) => {
        this.events = res.data.events;
      });
    },
    getMonths() {
      this.months = moment.monthsShort();
    },
    getYears() {
      let years: string[] = [];
      let currentYear = moment().format("YYYY");
      let start = Number(currentYear) - 5;
      let end = Number(currentYear) + 2;
      for (let year = start; year <= end; year++) {
        years.push(`${year}`);
      }
      this.years = years;
    },
    time(event: IEvent) {
      return moment(event.start_time).format("MMM DD, YYYY @ HH:mm");
    },
  },
});
