













































import Vue from "vue";
import moment from "moment";
import { IMedia } from "@/utils/types";

export default Vue.extend({
  props: {
    blog: Object,
  },
  computed: {
    time() {
      return moment(this.blog.created_at).format("MMM D, YYYY");
    },
    image() {
      return this.blog.media.find((i: IMedia) => i.collection_name == "cover")
        .original_url;
    },
  },
});
