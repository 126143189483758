







import Vue from "vue";
import Top from "@/components/Nav/Components/Top.vue";
import Navigation from "@/components/Nav/Components/Navigation.vue";

export default Vue.extend({
  props: {
    background: Boolean,
  },
  components: {
    Top,
    Navigation,
  },
  data: function () {
    return {
      scrollPosition: 0,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  computed: {
    isNormal(): boolean {
      return (
        this.scrollPosition > 50 || this.$route.path != "/" || this.background
      );
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
});
