









import store from "@/store";
import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      verse: "" as string,
    };
  },
  mounted() {
    axios
      .get("https://labs.bible.org/api/?passage=random&type=json")
      .then((res) => {
        const { bookname, chapter, verse, text } = res.data[0];
        this.verse = `${text} -  ${bookname} ${chapter}:${verse}`;
      });
  },
  computed: {
    name: () =>
      store.state.user.has_member ? store.state.user.member.firstname : " ",
  },
});
