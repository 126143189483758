
















import Vue from "vue";
import UpcomingEvents from "@/components/Events/UpcomingEvents/UpcomingEvents.vue";
import UpcomingEventsCalendar from "@/components/Events/UpcomingEvents/UpcomingEventsCalendar.vue";
import PreviousEvents from "@/components/Events/PreviousEvents/PreviousEvents.vue";
import { IEvent } from "@/utils/types";
import { fetchUpcomingEvents } from "@/utils/api";
import store from "@/store";
import PageProgress from "../../components/PageProgress/PageProgress.vue";
export default Vue.extend({
  data() {
    return {
      upcomingEvents: [] as IEvent[],
      loading: true,
    };
  },
  mounted() {
    this.getUpcomingEvents();
  },
  components: {
    UpcomingEvents,
    UpcomingEventsCalendar,
    PreviousEvents,
    PageProgress,
  },
  methods: {
    getUpcomingEvents() {
      fetchUpcomingEvents(store.state.user.member?.id)
        .then((res) => {
          this.upcomingEvents = res.data.events;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
