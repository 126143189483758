



































import Vue from "vue";
import PersonalDetailsForm from "@/components/Forms/Registration/PersonalDetails.vue";
import MedicationDetails from "@/components/Forms/Registration/MedicalDetails.vue";
import ChurchMembership from "@/components/Forms/Registration/ChurchMembership.vue";
import FccMembership from "@/components/Forms/Registration/FccMembership.vue";
import router from "@/router";
import store from "@/store";
import Container from "@/components/Dashboard/_DashboardContainer.vue";

export default Vue.extend({
  components: {
    PersonalDetailsForm,
    MedicationDetails,
    ChurchMembership,
    FccMembership,
    Container,
  },
  methods: {
    onSave() {
      router.back();
    },
  },
  computed: {
    member: () => store.state.user.member,
    active() {
      return this.$route.params.form;
    },
  },
});
