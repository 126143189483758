<template>
  <div class="mt-28">
    <div class="img" :style="{ backgroundImage: `url(${img})` }" v-if="blog">
      <div class="w-full h-full overlay py-16">
        <div class="py-16 text-xl md:text-4xl font-bold container text-white">
          {{ blog.title }}
        </div>
      </div>
    </div>
    <div class="container my-4">
      <div class="my-4 flex justify-between text-gray-400 text-sm">
        <div>{{ blog.author }}</div>
        <div>{{ date }}</div>
      </div>
      <div v-html="blog.body"></div>
    </div>
    <Banner />
    <div class="container">
      <Blog />
    </div>
    <page-progress v-if="loading" />
  </div>
</template>

<script>
import Vue from "vue";
import Banner from "@/components/Home/Banner/Banner.vue";
import { fetchBlog } from "@/utils/api";
import Blog from "@/components/Home/Blog/Blog.vue";
import moment from "moment";
import PageProgress from "@/components/PageProgress/PageProgress.vue";

export default Vue.extend({
  name: "About",
  components: {
    Banner,
    Blog,
    PageProgress,
  },
  data() {
    return {
      blog: null,
      loading: true,
    };
  },
  mounted() {
    fetchBlog(Number(this.$route.params.blog))
      .then((res) => {
        this.blog = res.data.blog;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  watch: {
    "$route.params.blog": function () {
      fetchBlog(Number(this.$route.params.blog)).then((res) => {
        this.blog = res.data.blog;
      });
    },
  },
  methods: {
    getBlog() {
      fetchBlog(Number(this.$route.params.blog)).then((res) => {
        this.blog = res.data.blog;
      });
    },
  },
  computed: {
    img() {
      return this.blog?.media?.find((i) => i.collection_name == "cover")
        ?.original_url;
    },
    date() {
      return moment(this.blog.created_at).format("DD MMM YY HH:mm");
    },
  },
});
</script>

<style>
.overlay {
  background: rgba(0, 0, 0, 0.6);
}
.img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
