

































import Vue from "vue";
import store from "@/store";
import { logout } from "@/utils/api";
import { clearSession } from "@/utils/helpers";

export default Vue.extend({
  props: {
    onLogin: Function,
  },
  data() {
    return {
      showPassword: false,
      generalError: "",
      formErrors: {} as { email?: Array<string>; password?: Array<string> },
      submitting: false,
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    signout() {
      this.$swal
        .fire({
          text: "Are you sure you want to logout?",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            logout().then(() => {
              clearSession();
              this.$router.push("/");
            });
          }
        });
    },
  },
  computed: {
    authenticated() {
      return store.state.user.authenticated;
    },
    has_member() {
      return store.state.user.has_member;
    },
  },
});
