
























import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  computed: {
    contacts: () => store.state.profile.contacts,
  },
});
