















import Vue from "vue";
import BlogCard from "@/components/Home/Blog/BlogCard.vue";
import SectionTitle from "@/components/Title/SectionTitle.vue";
import { IEvent } from "@/utils/types";
import { fetchBlogs } from "@/utils/api";

export default Vue.extend({
  components: {
    BlogCard,
    SectionTitle,
  },
  mounted() {
    this.getEvents();
  },
  data() {
    return {
      blogs: [] as IEvent[],
    };
  },
  methods: {
    getEvents() {
      fetchBlogs().then((res) => {
        this.blogs = res.data.blogs.data;
      });
    },
  },
});
