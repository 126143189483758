





































































































































































































































import Vue from "vue";
import FormHeader from "@/components/Forms/FormHeader.vue";
import FieldError from "@/components/Forms/FieldError.vue";
import { createMember, updateMember } from "../../../utils/api";
import store, { actionTypes } from "@/store";
import moment from "moment";
import { saveSession } from "@/utils/helpers";

export default Vue.extend({
  props: {
    onSave: Function,
    update: Boolean,
  },
  components: {
    FormHeader,
    FieldError,
  },
  mounted() {
    this.setFormData();
  },
  data() {
    return {
      showPassword: false,
      generalError: "",
      formErrors: {} as any,
      submitting: false,
      formData: {
        firstname: "",
        surname: "",
        dob: moment().subtract(18, "years").format("YYYY-MM-DD"),
        gender: "",
        marital_status: "",
        district_id: 0,
        village: "",
        ta: "",
        class_id: 0,
        occupation: "",
        contact_address: "",
        contact_number_cell: "",
        contact_number_other: "",
        email: "",
        next_of_kin_contact_address: "",
        next_of_kin_contact_number_cell: "",
        next_of_kin_contact_number_other: "",
        next_of_kin_email: "",
        region_id: 0,
        country_id: 0,
        year_joined: moment().format("YYYY-MM"),
      },
      dob: false,
      year_joined: false,
      districts: store.state.meta.districts,
      villages: [],
      ta: [],
      occupations: [],
      regions: store.state.meta.regions,
      countries: store.state.meta.countries,
      classes: store.state.meta.member_classes,
    };
  },
  methods: {
    onSubmit(e: any) {
      e.preventDefault();
      if (this.submitting) return;
      this.submitting = true;
      if (this.update) {
        updateMember(store.state.user.member.id, {
          ...this.formData,
          year_joined: moment(this.formData.year_joined).format("YYYY-MM-DD"),
        })
          .then((res) => {
            store.dispatch(actionTypes.setMember, res.data.member);
            this.onSave();
          })
          .catch((e) => {
            this.submitting = false;
            if (!e.response || !e.response.data || !e.response.data.errors) {
              this.generalError = "There was an error. Please try again later";
              return;
            }
            this.formErrors = e.response.data.errors;
          });
        return;
      }
      createMember({
        ...this.formData,
        year_joined: moment(this.formData.year_joined).format("YYYY-MM-DD"),
      })
        .then((res) => {
          saveSession(res.data.data);
          this.onSave();
        })
        .catch((e) => {
          this.submitting = false;
          if (!e.response || !e.response.data || !e.response.data.errors) {
            this.generalError = "There was an error. Please try again later";
            return;
          }
          this.formErrors = e.response.data.errors;
        });
    },
    onCancel() {
      this.$router.go(-1);
    },
    hasError(field: string) {
      return this.formErrors[field as any] != null;
    },
    getError(field: string) {
      const error = this.formErrors[field as any];
      return error ? error[0] : "";
    },
    setFormData() {
      if (this.update && store.state.user.has_member) {
        const m = store.state.user.member;
        this.formData = {
          firstname: m.firstname,
          surname: m.surname,
          dob: moment(m.dob).format("YYYY-MM-DD"),
          gender: m.gender,
          marital_status: m.marital_status,
          district_id: m.district_id,
          village: m.village,
          ta: m.ta,
          class_id: m.class_id,
          occupation: m.occupation,
          contact_address: m.contact_address,
          contact_number_cell: m.contact_number_cell,
          contact_number_other: m.contact_number_other,
          email: m.email,
          next_of_kin_contact_address: m.next_of_kin_contact_address,
          next_of_kin_contact_number_cell: m.next_of_kin_contact_number_cell,
          next_of_kin_contact_number_other: m.next_of_kin_contact_number_other,
          next_of_kin_email: m.next_of_kin_email,
          region_id: m.region_id,
          country_id: m.country_id,
          year_joined: moment(m.year_joined).format("YYYY-MM"),
        };
      }
    },
  },
  computed: {
    max: () => {
      return moment().format("YYYY");
    },
    maxDOB: () => {
      return moment().subtract(18, "years").format("YYYY-MM-DD");
    },
  },
});
