import store, { actionTypes } from "@/store";
import { fetchMeta, fetchProfile } from "./api";
import { IUser } from "./types";
import axios, { AxiosResponse } from "axios";

export const getProfile = async () => {
  return fetchProfile().then((res) => {
    store.dispatch(actionTypes.setProfile, res.data.data);
  });
};

export const getMeta = async () => {
  return fetchMeta().then((res) => {
    store.dispatch(actionTypes.setMeta, res.data.data);
  });
};

export const checkLogin = () => {
  getSavedSession();
};

export const getToken = () => {
  try {
    const data = sessionStorage.getItem("user");
    if (!data) return null;
    const user = JSON.parse(data);
    return user.token;
  } catch (error) {
    return false;
  }
};

export const saveSession = (user: IUser) => {
  const userData = { ...user, authenticated: user.token.length > 0 };
  sessionStorage.setItem("user", JSON.stringify(userData));
  store.dispatch(actionTypes.setAuth, userData);
};

export const getSavedSession = () => {
  const data = sessionStorage.getItem("user");
  if (!data) return null;
  const user = JSON.parse(data);
  store.dispatch(actionTypes.setAuth, user);
  return user;
};

export const clearSession = () => {
  sessionStorage.clear();
  store.dispatch(actionTypes.setAuth, { authenticated: false });
};

export const apiConfig = {
  headers: {
    Authorization: `Bearer ${store.state.user.token}`,
  },
};

export const forceFileDownload = (response: AxiosResponse, title: string) => {
  console.log(title);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", title);
  document.body.appendChild(link);
  link.click();
};
export const downloadWithAxios = (url: string) => {
  return axios({
    method: "get",
    url: url.replace("/storage/", "/api/download/storage/"),
    responseType: "blob",
  });
};
