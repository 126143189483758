<template>
  <div class="mt-28 md:mt-30">
    <upcoming-event-card-full :event="event" />
    <div class="container">
      <section-title>About The Event</section-title>
      <div v-if="event" v-html="event.description"></div>
      <section-title v-if="event.media.length > 1">Resources</section-title>
      <div class="my-4">
        <div v-if="documents.length > 0">
          <div class="text-red-700 my-2 text-lg font-bold">Documents</div>
          <div class="grid gap-4 sm:grid-cols-2 md:grid-cols-4">
            <div
              class="shadow-md p-2 bg-white"
              v-for="item in documents"
              :key="item.id"
            >
              <div class="flex justify-between items-center">
                <div>
                  <div>{{ getLabel(item) }}</div>
                  <div class="text-sm text-gray-400">{{ item.file_name }}</div>
                </div>
                <font-awesome-icon
                  icon="download"
                  class="cursor-pointer"
                  @click="
                    () => onFileDownload(item.original_url, item.file_name)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="my-4" v-if="audios.length > 0">
          <div class="text-red-700 my-2 text-lg font-bold">Audios</div>
          <div class="grid gap-4 sm:grid-cols-2 md:grid-cols-4">
            <div
              class="shadow-md p-2 bg-white"
              v-for="item in audios"
              :key="item.id"
            >
              <div class="flex justify-between items-center">
                <div>
                  <div>{{ getLabel(item) }}</div>
                  <div class="text-sm text-gray-400">{{ item.file_name }}</div>
                </div>
                <font-awesome-icon
                  icon="download"
                  class="cursor-pointer"
                  @click="
                    () => onFileDownload(item.original_url, item.file_name)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-4" v-if="images.length > 0">
        <div class="text-red-700 my-2 text-lg font-bold">Images</div>
        <div>
          <div class="grid gap-6 sm:grid-cols-2 md:grid-cols-3">
            <div
              class="img-wrapper relative"
              v-for="image in images"
              :key="image.id"
            >
              <div
                class="image h-full w-full"
                :style="{ backgroundImage: `url(${image.original_url})` }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-progress v-if="loading" />
  </div>
</template>

<script>
import UpcomingEventCardFull from "@/components/Events/UpcomingEvents/UpcomingEventCardFullImg.vue";
import SectionTitle from "@/components/Title/SectionTitle.vue";
import { fetchEvent } from "@/utils/api";
import Vue from "vue";
import PageProgress from "../../components/PageProgress/PageProgress.vue";
import { downloadWithAxios, forceFileDownload } from "@/utils/helpers";

export default Vue.extend({
  components: {
    UpcomingEventCardFull,
    SectionTitle,
    PageProgress,
  },
  data() {
    return {
      event: null,
      loading: true,
    };
  },
  mounted() {
    fetchEvent(Number(this.$route.params.event))
      .then((res) => {
        this.event = res.data.event;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getLabel(item) {
      try {
        return JSON.parse(item.custom_properties).label;
      } catch (error) {
        return item.name;
      }
    },
    onFileDownload(path, filename) {
      downloadWithAxios(path).then((response) => {
        forceFileDownload(response, filename);
      });
    },
  },
  computed: {
    documents() {
      return this.event
        ? this.event.media.filter((m) => m.collection_name == "document")
        : [];
    },
    audios() {
      return this.event
        ? this.event.media.filter((m) => m.mime_type.split("/")[0] == "audio")
        : [];
    },
    images() {
      return this.event
        ? this.event.media.filter(
            (m) =>
              m.mime_type.split("/")[0] == "image" &&
              m.collection_name != "cover"
          )
        : [];
    },
  },
});
</script>

<style>
.img-wrapper {
  padding-top: 60%;
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1.5s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
