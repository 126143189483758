




















































import Vue from "vue";
import store from "@/store";
import { menuItems } from "../Nav/Components/Navigation.vue";
import { IMedia } from "@/utils/types";

export default Vue.extend({
  props: {
    onLogin: Function,
  },
  data() {
    return {
      menuItems: menuItems,
    };
  },

  computed: {
    user: () => store.state.user,
    img: () => {
      const image =
        store.state.user.has_member && store.state.user.member
          ? store.state.user.member.media.find(
              (i: IMedia) => i.collection_name == "profile"
            )?.original_url
          : null;
      return (
        image ??
        "https://res.cloudinary.com/c99/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1649373695/unnamed.png"
      );
    },
  },
});
