













import Vue from "vue";
import PersonalDetailsForm from "@/components/Forms/Registration/PersonalDetails.vue";
import MedicationDetails from "@/components/Forms/Registration/MedicalDetails.vue";
import ChurchMembership from "@/components/Forms/Registration/ChurchMembership.vue";
import FccMembership from "@/components/Forms/Registration/FccMembership.vue";

export default Vue.extend({
  components: {
    PersonalDetailsForm,
    MedicationDetails,
    ChurchMembership,
    FccMembership,
  },
  data() {
    return {
      form: 0,
    };
  },
  methods: {
    onNext() {
      this.form = this.form + 1;
    },
    onUserRegistered() {
      this.$router.push("/");
    },
  },
});
