





































































import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  computed: {
    banner: () => store.state.profile.banner,
    contact: () => store.state.profile.contacts,
  },
});
