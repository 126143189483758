









































import Vue from "vue";
import MenuItem from "@/components/Nav/Components/MenuItem.vue";
import MobileNav from "./MobileNav.vue";

export const menuItems = {
  unauthenticated: [
    {
      name: "Home",
      to: "/",
    },
    {
      name: "Events",
      to: "/events",
    },
    {
      name: "About Us",
      to: "/about",
    },
    {
      name: "Stories",
      to: "/blog",
    },
  ],

  authenticated: [
    {
      name: "Schedule",
      to: "/dashboard/schedule",
      icon: "mdi-calendar",
    },
    {
      name: "Financies",
      to: "/dashboard/finance",
      icon: "mdi-cash",
    },
    {
      name: "Profile",
      to: "/dashboard/profile",
      icon: "mdi-account",
    },
  ],
};

export default Vue.extend({
  components: {
    MenuItem,
    MobileNav,
  },
  data() {
    return {
      menuItems: menuItems,
    };
  },
});
