






































import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  computed: {
    authenticated: () => store.state.user.authenticated,
    about: () => store.state.profile.about.home,
    image: () =>
      store.state.profile.media?.find((i) => i.collection_name == "profile1")
        ?.original_url,
  },
});
