






















import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleMenu() {
      this.open = !this.open;
    },
  },
});
