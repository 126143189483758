



























import Vue from "vue";
import Nav from "@/components/Nav/Nav.vue";
import Footer from "./components/Footer/Footer.vue";
import { checkLogin, getMeta, getProfile } from "./utils/helpers";
import Preloader from "./components/Preloader/Preloader.vue";
import ErrorSVG from "./components/ErrorSVG/ErrorSVG.vue";

export default Vue.extend({
  name: "App",
  components: {
    Nav,
    Footer,
    Preloader,
    ErrorSVG,
  },
  mounted() {
    checkLogin();
    getProfile()
      .then(() => {
        getMeta().then(() => {
          this.error = "";
        });
      })
      .catch(() => {
        this.error =
          "There was an error while fetching data. Please reload the page";
      })
      .finally(() => {
        this.loading = false;
      });
  },

  data: () => ({
    loading: true,
    error: "",
  }),
  computed: {
    hasError() {
      return this.error.length > 0;
    },
  },
});
