











































import Vue from "vue";
import Container from "@/components/Dashboard/_DashboardContainer.vue";
import Hero from "@/components/Dashboard/Schedule/Hero.vue";
import Calendar from "@/components/Dashboard/Schedule/Calender.vue";
import MobileCalendar from "@/components/Dashboard/Schedule/MobileCalendar.vue";
import UpcomingEvents from "@/components/Dashboard/Schedule/UpcomingEvents.vue";

export default Vue.extend({
  components: {
    Container,
    Hero,
    Calendar,
    MobileCalendar,
    UpcomingEvents,
  },
});
