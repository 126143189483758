








import Vue from "vue";
import UpcomingEventsMiniCard from "@/components/Home/UpcomingEvents/UpcomingEventsMiniCard.vue";
export default Vue.extend({
  props: {
    events: Array,
  },
  components: {
    UpcomingEventsMiniCard,
  },
});
