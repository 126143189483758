








import Vue from "vue";
import Signup from "@/components/Forms/Registration/Signup.vue";
import router from "@/router";
import { IUser } from "../../utils/types";
import { saveSession } from "@/utils/helpers";

export default Vue.extend({
  components: {
    Signup,
  },
  methods: {
    onSubmit(user: IUser) {
      saveSession(user);
      if (user.has_member) {
        router.push("/");
        return;
      }
      router.push("/auth/registration-type");
    },
  },
});
