












import Vue from "vue";
import Landing from "@/components/Home/Landing.vue";
import UpcomingEventsMini from "@/components/Home/UpcomingEvents/UpcomingEventsMini.vue";
import About from "@/components/Home/About/About.vue";
import Banner from "@/components/Home/Banner/Banner.vue";
import Blog from "@/components/Home/Blog/Blog.vue";
import PreviousEvents from "@/components/Home/PreviousEvents/PreviousEvents.vue";

import { fetchUpcomingEvents } from "@/utils/api";
import store from "@/store";
import PageProgress from "../components/PageProgress/PageProgress.vue";

export default Vue.extend({
  name: "Home",
  components: {
    Landing,
    UpcomingEventsMini,
    About,
    Banner,
    PreviousEvents,
    Blog,
    PageProgress,
  },
  mounted() {
    this.getEvents(store.state.user.member?.id);
  },
  watch: {
    "$store.state.user.member": function () {
      this.getEvents(store.state.user.member?.id);
    },
  },
  data() {
    return {
      events: [],
      loading: true,
    };
  },
  methods: {
    getEvents(id: any) {
      fetchUpcomingEvents(id)
        .then((res) => {
          this.events = res.data.events;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
