import { IProfile, IState, IUser } from "@/utils/types";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: true,
    user: {
      authenticated: false,
    },
    meta: {} as any,
    profile: {} as IProfile,
  } as IState,
  mutations: {
    setAuth(state, payload) {
      state.user = payload;
    },
    setMember(state, payload) {
      state.user.member = payload;
    },
    setProfile(state, payload) {
      state.profile = payload;
    },
    setLoadingFalse(state) {
      state.loading = false;
    },
    setMeta(state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    setAuth({ commit }, user: IUser) {
      commit("setAuth", user);
    },
    setMember({ commit }, member) {
      commit("setMember", member);
    },
    setMeta({ commit }, meta: any) {
      commit("setMeta", meta);
    },
    setProfile({ commit }, profile: IProfile) {
      commit("setProfile", profile);
      commit("setLoadingFalse");
    },
  },
  modules: {},
});

export const actionTypes = {
  setAuth: "setAuth",
  setProfile: "setProfile",
  setMeta: "setMeta",
  setMember: "setMember",
};
