























import { IMedia } from "@/utils/types";
import moment from "moment";
import Vue from "vue";
export default Vue.extend({
  props: {
    event: Object,
  },
  methods: {
    onClick(id: any) {
      this.$router.push(`/events/${id}`);
    },
  },
  computed: {
    time() {
      return moment(this.event).format("DD MMM, YYYY");
    },
    image() {
      const img = this.event.media.find(
        (i: IMedia) => i.collection_name == "cover"
      );
      if (img) return img.original_url;
      return "~@/assets/images/bg.jpg";
    },
  },
});
